.logo-name {
  color: #000;
  font-family: "Oswald", sans-serif;
  font-size: 60px;
  font-weight: 500;
}
header {
  max-width: 1080px;
  margin: 0 auto;
  padding: 2.5rem 0;
}
.main-nav {
  display: flex;
  justify-content: space-between;
}
.nav-list {
  display: flex;
  list-style: none;
  padding-top: 28px;
}
.nav-list li {
  margin-right: 20px;
}
.nav-link a {
  text-decoration: none;
  color: #000;
  font-size: 22px;
  font-family: "Playfair Display", serif;
}
.nav-link a.active {
  color: #d2042d;
}
.nav-link a:hover {
  border-bottom: 2px solid #000;
}
@media screen and (max-width: 800px) {
  header {
    padding: 0;
  }
  .logo-name {
    font-size: 70px;
  }
  .main-nav {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .container {
    width: 100%;
    max-width: none;
  }
  .nav-list {
    padding-top: 0;
  }
  .nav-link a {
    font-size: 30px;
  }
  .hero {
    padding: 20px;
  }
  .main-hdr {
    font-size: 23px;
    line-height: 38px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .hero {
    padding: 20px 40px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .hero {
    padding: 20px 40px;
  }
  .main-nav {
    padding: 0 40px;
  }
}
