* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.main-hdr {
  font-family: "Playfair Display", serif;
  font-size: 50px;
  font-weight: 400;
}
.main-hdr span {
  color: #d2042d;
}
.hero {
  padding: 40px 0;
}

.container {
  margin: 0 auto;
  max-width: 1080px;
}
.avatar {
  display: block;
  border-radius: 50%;
  width: 300px;
  height: 100%;
}
.about {
  display: flex;
}
.bio {
  padding-left: 40px;
  max-width: 600px;
}
.bio p {
  padding-bottom: 20px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}
.links {
  color: #d2042d;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}
.links:hover {
  border-bottom: 2px solid #000;
}
.social-list {
  display: flex;
}
.social-list li {
  margin-right: 15px;
}
footer {
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  top: 50px;
  padding-bottom: 40px;
}
.legal {
  font-family: "Open Sans", sans-serif;
}
/* Portfolio Section */
.heading {
  font-size: 30px;
  font-family: "Oswald";
  text-transform: uppercase;
  letter-spacing: 2px;
}
.wrapper {
  /* display: flex;
  flex-wrap: nowrap; */
}
.project-info {
  max-width: 380px;
  padding-left: 30px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}
.project-info h3 {
  font-family: "Playfair Display";
  font-size: 27px;
  font-weight: 700;
}
.project-info p {
  padding: 15px 0;
}
.portfolio {
  display: flex;
  margin: 30px 0;
}
.portfolio img {
  border: 5px solid rgba(0, 0, 0, 0.7);
}
.link-btn {
  font-size: 16px;
  display: block;
  border-radius: 5px;
  padding: 10px 20px;
  background: #000;
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}
.link-btn:hover {
  background-color: #d2042d;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
.source:hover {
  background-color: #0a75ad;
}
.proj-links-list {
  display: flex;
}
.proj-links-list li {
  margin-right: 15px;
}
.proj-links-list li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 800px) {
  .about {
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    width: 200px;
  }
  .bio {
    padding: 20px;
    font-size: 20px;
  }
  .portfolio {
    flex-direction: column;
  }
  .portfolio img {
    height: 100%;
    width: 100%;
    display: block;
  }
  .project-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: none;
  }
  .heading {
    display: none;
  }
  footer {
    top: 0;
    max-width: none;
    padding-left: 20px;
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .bio {
    max-width: none;
    padding: 40px;
  }
  .avatar {
    width: 300px;
  }
  footer {
    padding-left: 40px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .main-hdr {
    font-size: 40px;
  }
  .wrapper {
    margin-left: 40px;
  }
  .avatar {
    margin-left: 40px;
  }
  .heading {
    margin-left: 40px;
  }
  .portfolio img {
    width: 550px;
    height: 100%;
  }
  footer {
    padding-left: 40px;
  }
}
